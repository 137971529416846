<template>
  <li class="item">
    <section class="item--question"  @click="showAnswer = !showAnswer">
      <span>{{ faq.question }}</span>
      <OpenButton :showText="showAnswer" class="open-button"/>
    </section>
    <p v-html="faq.answer" class="item--answer" v-if="showAnswer"></p>
  </li>
</template>

<script>
import OpenButton from '@/components/ui/buttons/OpenButton'


export default {
  name: 'Answer',
  components: {
    OpenButton
  },
  data() {
    return {
      showAnswer: false,
    }
  },
  props: {
    faq: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

.item {
  margin-top: 19px;
  padding-bottom: 10px;
  border-bottom: 1px solid #3D3D3D;
  width: 100%;


  &--question {
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }

  &--answer {
    margin-top: 17px;
    font-size: 14px;
    font-weight: 300;
    color: #E5E5E5;
  }
}

.open-button {
  @media screen and (max-width: 640px) {
    display: none;
  }
}

</style>
