<template>
  <article class="faq">
    <img src="@/assets/FAQ/limitless.png" alt="" class="faq--image">
    <ul class="faq--list">
      <Answer v-for="faq in faqList" :key="faq.question" :faq="faq" />
    </ul>
  </article>
</template>

<script>
import Answer from './Answer'
import API from '@/API'


export default {
  name: 'FAQ',
  components: {
    Answer
  },
  data() {
    return {
      faqList: []
    }
  },
  async created() {
    this.faqList = await API.getFAQ()
  }
}
</script>

<style scoped lang="scss">

.faq {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--image {
    width: 60%;
    
    @media screen and (max-width: 640px) {
      width: 90%;
    }
  }

  &--list {
    width: 60%;

    @media screen and (max-width: 640px) {
      width: 90%;
    }
  }
}
</style>
